
/* eslint-disable no-unused-vars */
import { setToast } from '@/store';
import { getDocs, query, where, documentId } from '@firebase/firestore';
import { getStorage, ref as firebaseRef, uploadString } from '@firebase/storage';
import { format } from 'date-fns';
import { he } from 'date-fns/locale';
import { chunk } from 'lodash';
import { defineComponent } from 'vue'
import { collectionRef, fetchCache } from '../helpers';

type tokenUser<CAT = number> = { createdAt: CAT, token: string, autoSaves?: any }

const prepareNotFilled = async () => {
    const docs = await getDocs(query(collectionRef('tokens'), where('usedAt', '==', null)));
    const didNotFill: { [key: string]: tokenUser } = {};
    const subjectIds: string[] = [];
    docs.forEach(doc => {
        const { createdAt, id } = doc.data();
        if(id.startsWith('t')) return;

        didNotFill[id] = { createdAt, token: doc.id };
        subjectIds.push(id);
    });

    const autoSaves = await Promise.all(chunk(subjectIds, 10).map(idChunk => {
        return getDocs(query(collectionRef('autoSaves'), where(documentId(), 'in', idChunk)));
    }));

    autoSaves.map(chunk => chunk.forEach(doc => {
        const data = doc.data();
        if(!data.records.length) return;

        didNotFill[doc.id].autoSaves = data.records.every((rec: any) => rec.goingTo === 'agreement')
            ? `entered_${data.records.length}`
            : data;
    }));

    return Object.values(didNotFill).sort(({ createdAt: createdA }, { createdAt: createdB }) => createdA - createdB);
}

export default defineComponent({
    async beforeMount(){
        const dataRef = firebaseRef(getStorage(), 'didNotFill.json');
        const cachedRecords = await fetchCache<tokenUser>(dataRef);
        const records = cachedRecords || await prepareNotFilled();

        if(!cachedRecords)
            await uploadString(dataRef, JSON.stringify(records));

        this.list = records.map(rec => ({ ...rec,  createdAt: format(new Date(rec.createdAt), 'd בMMMM yy', { locale: he }) }));
    },
    data: () => ({ list: [] as tokenUser<string>[] }),
    methods: {
        async copyToken(token: string){
            await navigator.clipboard.writeText(token);
            setToast({ text: 'ההעתקה בוצעה בהצלחה', type: 'success' });
        }
    }
})
